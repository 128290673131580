<template>
  <v-app>
    <dashboard-core-app-bar
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="expandOnHover"
    />

    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />

    <dashboard-core-view />

    <base-material-snackbar
      v-model="snackbar.state"
      :timeout="snackbar.time"
      :type="snackbar.color"
      v-bind="{
        [parsedDirection[0]]: true,
        [parsedDirection[1]]: true
      }"
    >
      <div class="d-flex justify-space-between">
        <div class="mr-6">
          <div
            v-if="snackbar.title"
            class="font-weight-bold"
          >
            {{ snackbar.title }}1
          </div>
          <div class="snack-text">
            {{ snackbar.text }}
          </div>
        </div>
        <v-btn
          x-small
          class="v-alert__dismissible"
          fab
          color="white"
          @click="snackbar.state = false"
        >
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
    </base-material-snackbar>
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreView: () => import('./components/core/View'),
    },
    data: () => ({
      expandOnHover: true,
    }),
    computed: {
      parsedDirection () {
        return this.snackbar.direction.split(' ')
      },
      snackbar: {
        get () {
          return this.$store.state.snackbar
        },
        set (val) {
          this.$store.commit('setSnackbar', val)
        },
      },
    },
    watch: {
      expandOnHover (newVal) {
        if (newVal) {
          localStorage.setItem('expandOnHover', '1')
        } else {
          localStorage.clear('expandOnHover')
        }
      },
    },
    created () {
      if (localStorage.expandOnHover) {
        this.expandOnHover = true
      }
    },
  }
</script>
<style>
  .snack-text{
    white-space: pre;
  }
  .portal-alert {
    position: absolute;
    top: 20px;
    right: 20px;

  }
</style>
